
#footer {

  > .container {
    background-color: #fff;
  }

  .footer-links {
    list-style: none;
    padding: 0;
    margin-left: 0;
    margin: 0 0 1.875rem 0;
    text-align: center;
    ul{
      margin: 0;
      padding: 0;
    }
    li {
      text-transform: uppercase;
      display: inline-block;
      padding: 0 2rem 0 0;
      &:last-child {
        padding: 0 0 0 0;
      }
    }
  }

  .social-icons {
    list-style: none;
    padding: 0;
    margin: 0 0 2em;
    text-align: center;
    ul {
      margin-left: 0;
      li {
        display: inline-block;
        padding: 0 1rem 0 0;
        &:last-child {
          padding: 0 0 0 0;
        }
        img {
          width: 3.375rem;
          height: 3.375rem;
        }
      }
    }
  }
  .address{
    margin: 1.5rem 0 0;
    p{
      font-size: 80%;
      color: #373a3c;
      a{
        color: #373a3c;
      }
    }
  }
  .copyright {
    margin: 0 0 1.5rem 0;
  }

}
