#header {
  background-color: #fff;
  padding: 15px 0;
}

.navbar.bg-faded {
  background: transparent;
  padding-top: 0;
  padding-bottom: 0;
}

img.site-logo {
  display: inherit;
  width: 100%;
  max-width: 260px;
  height: auto;
  margin: 25px 0 5px;
}

.navbar-nav > li > a, .dropdown-menu > li > a {
  font-size: 1em;
}

#header-cta {
  display: block;
  width: 100%;
  margin-bottom: 8px;
  padding: 0 15px;
}

.nav-icons {
  position: relative;

  .left, .right {
    width: 64px;
    height: 100%;
    position: absolute;
    z-index: 9999;
    display: none;
    fill: $tertiary-color;
    &.disabled {
      fill: $primary-color;
    }
  }
  .left {
    left: 0;
    background: -moz-linear-gradient(left,  rgba(255,255,255,1) 0%, rgba(255,255,255,1) 49%, rgba(255,255,255,0) 100%);
    background: -webkit-linear-gradient(left,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 49%,rgba(255,255,255,0) 100%);
    background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 49%,rgba(255,255,255,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 );
  }
  .right {
    right: 0;
    background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 51%, rgba(255,255,255,1) 100%);
    background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 51%,rgba(255,255,255,1) 100%);
    background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 51%,rgba(255,255,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 );
  }
}

#navbar-header {

}

.navbar-brand {
  min-width: 200px;
  height: auto;
}

.navbar-nav {
  margin-left: 0;
  width: 100%;
  text-align: right;
  white-space: nowrap;
  &.dragged {}

  .nav-item {
    display: inline-block;
    float: none;
  }
}

.navbar-nav > li {
  padding: 0 1.4rem 0 1.4rem;
  > a {
    transition: all 0.2s;
    text-transform: uppercase;
    .nav-icon {
      display: block;
      height: 4rem;
      text-align: center;
      svg {
        transition: all 0.2s;
        fill: $primary-color;
      }
    }
    &.dropdown-toggle {
      span.caret {}
    }
    &:hover, &:focus, &:active {
      svg {
        fill: $tertiary-color;
      }
    }
  }
  &.active {
    > a {
      .nav-icon {
        svg {
          fill: $tertiary-color;
        }
      }
    }
  }
}

.navbar-nav > li > ul.dropdown-menu {
  text-align: center;
  li.nav-item {
    width: 100%;
    padding: 2px 15px;
    &:hover, &:active, &:focus {
    }
    a {
      color: $primary-color;
      &:hover, &:active {

      }
    }
  }
}


/* Responsive queries */
@include media-breakpoint-down(sm) {
  .navbar-nav > li {
    padding: 0 0.5rem 0 0.5rem;
    &:first-child {
      padding: 0 0.5rem 0 2.5rem;
    }
    &:last-child {
      padding: 0 2.5rem 0 0.5rem;
    }
    > a {
      .nav-icon {
        height: 2rem;
        svg {
          width: 2rem;
          height: 2rem;
        }
      }
    }
  }
}

@include media-breakpoint-down(xs) {

}