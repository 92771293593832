// Colors
$brand-primary:         #888888;
$primary-color:   $brand-primary;
$secondary-color: #f98c37;
$tertiary-color:  #0091c0;
$quaternary-color: #666666;


$btn-default-bg: #ff9c3b;
$btn-default-bg-active: #f98c37;
$btn-primary-bg: #0080bc;
$btn-primary-bg-active: #069ed0;

// Body
$body-bg: #f5f8fa;


// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: #3097D1;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger: #bf5329;


// Typography
$font-family-base: 'Open Sans', sans-serif;
$link-color: $tertiary-color;
$link-hover-color: lighten($tertiary-color, 10);
$font-size-base: 1rem;
$line-height-base: 1.6;
$text-color: #636b6f;


// Navbar
$navbar-default-bg: #fff;


// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;
