html {
  -webkit-font-smoothing: antialiased;
}
html, body {
  overflow-x: hidden;
  font-family: $font-family-base;
}


@media
only screen and (-webkit-min-device-pixel-ratio: 1.25),
only screen and ( min-device-pixel-ratio: 1.25),
only screen and ( min-resolution: 200dpi),
only screen and ( min-resolution: 1.25dppx)
{ 
  //Was throwing an error on Safari 10 and 9 🤔
  //-webkit-font-smoothing: subpixel-antialiased;
}


#app{
  *{
    transition: all .3s ease;
  }
}


* {
  margin: 0;
  padding: 0;
}

body {
  background: url('/images/body-bg.jpg');
}

button, input, optgroup, select, textarea {
  font-family: $font-family-base;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

#content {
  padding: 0 0 25px 0;
  background-color: #fff;
}

h1, h2, h3, h4, h5 {
  color: $quaternary-color;
  line-height: 1.2;
}

h1, h2 {
  font-weight: 300;
}
h3 {
  font-weight: 300;
  a:after{
    display: none;
  }
}

p{
  font-size: 1.125rem;
  color: $quaternary-color;
}
.text-center{
  @extend .text-xs-center !optional;
  img{
    margin: 0 auto;
  }
}

ul, ol {
  margin-left: 3%;
  color: $quaternary-color;
}


a{
  color: $tertiary-color;
  transition: all 0.2s ease;
   &:hover, &:focus, &:active {
     text-decoration: none;
     color: $secondary-color;
     transition: all 0.2s ease;
   }
 }



.oembed{
  iframe{
    width: 100% !important;
  }
}