
// Fonts
@import url('https://fonts.googleapis.com/css?family=Open+Sans');


// Bootstrap
@import "~bootstrap/scss/bootstrap.scss";
@import "~font-awesome/scss/font-awesome";

// Variables
@import "common/variables";
@import "common/global";


@import "components/em_icons";
@import "components/buttons";
@import "layouts/header";
@import "layouts/footer";
@import 'layouts/layout';
@import 'components/buttons';

//@import "components/comments";
//@import "components/forms";
//@import "components/grid";
//@import "components/blocks";
//@import "components/pagenavigation";
//@import "components/breadcrumbs";
//@import "components/cards";
//@import "layouts/sidebar";
//@import "layouts/posts";
//@import "layouts/pages";


