.btn {
  transition: all 0.2s;
  font-size: 1.25rem;
  transform: translateY(0);
  border: 0;
  padding: .625rem 2.125rem;
  border-radius: 50px;
  em {
    transition: all 0.2s;
    transform: scale(1);
  }
  &:focus, &:hover{
    transform: translateY(-2px);
    box-shadow: 0 5px 1px rgba(0,0,0,0.12);
    border: 0;
    em {
      transform: scale(1.2);
    }
  }
  &:active {
    transform: translateY(2px);
    box-shadow: 0 -2px 0px rgba(0,0,0,0.12);
    em {
      transform: scale(1);
    }
  }

  .btn-content {
    display: inline-block;
    vertical-align: middle;
  }

  &.btn-default {
    color: #fff;
    background-color: $btn-default-bg;
    &:focus, &:hover, &:active {
      background-color: $btn-default-bg-active;
    }
  }

  &.btn-primary {
    color: #fff;
    background-color: $btn-primary-bg;
    &:focus, &:hover, &:active {
      background-color: $btn-primary-bg-active;
    }
  }
  &.btn-variant {
    color: $quaternary-color;
    background-color: none;
    position: relative;
    display: block;
    max-width: 200px;
    margin: auto;
    &:before, &:after{
      content: "";
      display: block;
      position: absolute;
      transition: all 0.2s;
    }
    &:before{
      right: 0;
      top: 0;
      bottom: 0;
      width: 0;
      height: 0;
      border-width: 3px;
      border-style: solid;
      border-bottom-color: transparent;
      border-left-color: transparent;
      transform: rotate(45deg);
      margin: auto 0;
    }
    &:after{
      right: 0;
      top: 0;
      bottom: 0;
      position: absolute;
      height: 1px;
      box-shadow: inset 0 0 0 32px;
      width: 30px;
      transform-origin: right top;
      margin: auto 0;
    }
    &:focus, &:hover, &:active {
      background-color: none;
      transform: translateY(0px);
      box-shadow: none;
      &:before{
          transition: all 0.2s;
          transform: translateX(6px) rotate(45deg);
      }
      &:after{
        transition: all 0.2s;
        transform: translateX(6px);
      }
    }
  }

  &.btn-circle {
    width: 110px;
    height: 110px;
    padding: 40px 25px 40px 10px;
    font-size: 1.25rem;
    line-height: 1.33;
    border-radius: 55px;
    border: 3px solid #fff;
    &:after {
      content: url("/dist/images/arrows_right.svg");
      width: 2.5rem;
      height: 2.5rem;
      display: block;
      position: absolute;
      right: 5px;
      top: 50%;
      margin-top: -1.25rem;
    }
  }

  &.dropdown-toggle {
    font-weight: 300;
    font-size: 1.5rem;
    border-radius: 10px;
    background-color: #fff;
    color: darken($primary-color, 5);
    &:after {
      margin-left: 3rem;
      color: $secondary-color;
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 30px;
      height: 30px;
      right: 26px;
      border: 1px solid $secondary-color;
      top: 10px;
      border-radius: 100%;
    }
  }

}

.button.dropdown-menu {
  width: 100%;
  border-radius: 0;
  .dropdown-item {
    color: $primary-color;
    font-size: 1.125rem;
  }
}
