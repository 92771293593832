em.icon {
  display: inline-block;
  width: 2.375rem;
  height: 2.375rem;
  border-radius: 50px;
  border: 2px solid #fff;
  vertical-align: middle;
  margin-right: 8px;

  /* Icons */
  &.phone {
    &:after {
      content: url("/images/phone.svg");
    }
  }
  &.right-arrow {
     &:after {
       content: url("/images/arrows_right.svg");
     }
   }
  &.left-arrow {
    &:after {
      content: url("/images/arrows_left.svg");
    }
  }

  /* Colors */
  &.nerdy-orange {
    background-color: $secondary-color;
  }
  &.nerdy-blue {
    background-color: $tertiary-color;
  }

  /* Sizes */
  &.sm {
    width: 1rem;
  }
  &.lg {
    width: 2rem;
  }
  &.xl {
    width: 3rem;
  }

}

.btn {
  em.icon {
    margin: -2px 12px -2px -26px;
  }
}