.calc-tasks{
  text-align: center;
  padding: 25px 0;
  .btn{
    margin: 0 auto;
  }
}

//Plus Icon
.plus-icon{
  width: 0.9em;
  height: 0.9em;
  border-radius: 50%;
  border: 1px solid;
  position: relative;
  margin: 0 8px 0 0;
  display: inline-block;
  &:after{
    content: '+';
    position: absolute;
    font-size: 1.2em;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
    height: 1.2em;
  }
}

.create-task{
  width: 100%;
  margin-top: 55px;
}

table{
  display: block;
  position: relative;
  &.table-striped tbody tr:nth-of-type(odd){
    background-color: rgba($quaternary-color, 0.3);
  }
  thead{
    width: 100%;
    color: $quaternary-color;
    a{
      color: $quaternary-color;
      i{
        padding: 0 0 0 5px;
      }
      &:hover{
        color: $quaternary-color;
        text-decoration: none;
      }
    }
  }
  tbody{
    width: 100%;
  }
  @include media-breakpoint-up(md) {
    display: table !important;
  }
}
//row-move
.table-row-move {
  transition: all 0.4s ease-in-out !important;
  transform: scale(1.09);
  &:nth-of-type(odd){
    transform: translateX(-3%);
  }
  &:nth-of-type(even){
    transform: translateX(3%);
  }
}

.tooltip{
  opacity:1;
}